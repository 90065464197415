import React from "react";
import { graphql } from "gatsby";
import Project from "../components/project";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      publishedAt
      title
      slug {
        current
      }
      heroHeading
      _rawHeroImg(resolveReferences: { maxDepth: 10 })
      deliverables
      _rawBody(resolveReferences: { maxDepth: 20 })
      _rawExcerpt(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const ProjectTemplate = (props) => {
  const { data = {}, errors } = props;
  const project = data.project
  const img = project && project._rawHeroImg
  return (
    <Layout textWhite={true}>
      {errors && <SEO title="GraphQL Error" />}
      {project && (
        <SEO
          title={project.title || "Untitled"}
          description={toPlainText(project._rawExcerpt)}
          image={img}
        />
      )}
      {project && <Project {...project} />}
    </Layout>
  );
};

export default ProjectTemplate;
