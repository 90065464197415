import React, { useState, useRef, useCallback } from 'react'
import Img from 'gatsby-image'
import { buildImageObj, getFluidGatsbyImage } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import { Waypoint } from 'react-waypoint'
import { Box } from 'rebass/styled-components'

const getThumbnailImgUrl = thumbnail =>
  imageUrlFor(buildImageObj(thumbnail))
    .fit('fill')
    .auto('format')
    .url()

function Video ({ thumbnail, source }) {
  const [videoProps, setVideoProps] = useState({})
  const videoRef = useRef(null)
  const onEnter = useCallback(async () => {
    try {
      if (videoRef.current) {
        const playing = await videoRef.current.play()
      }
    } catch (error) {
      // If browser doesn't support autoplaying, let's
      // show the video controls
      setVideoProps({
        ...videoProps,
        autoPlay: true,
        controls: true
      })
    }
  }, [videoRef, videoProps, setVideoProps])
  const handleClick = () => setVideoProps({ ...videoProps, controls: true })
  return (
    <Waypoint onEnter={onEnter}>
      <Box
        overflow='hidden'
        className='portableText--video'>
        <Box mx={['-15%', 0]}>
          <video
            onClick={handleClick}
            style={{ width: '100%', objectFit: 'cover' }}
            ref={videoRef}
            loop
            muted
            playsInline
            poster={getThumbnailImgUrl(thumbnail)}
            {...videoProps}
      >
            <source type='video/mp4' src={source.asset.url} />
          </video>
        </Box>
      </Box>
    </Waypoint>
  )
}

export default Video
