import React from 'react'
import { Heading, Text } from 'rebass/styled-components'
// import { H1, H2, H3, H4, Span } from '../Type';
import { H1, H2, H3, H4, H5, H6, P } from '../Type'
import BaseBlockContent from '@sanity/block-content-to-react'

const TypeMap = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  normal: P
}

const BlockContent = props => {
  const { style = 'normal' } = props.node
  const Component = TypeMap[style] || P
  return <Component {...props} />

  // if (/^h\d/.test(style)) {
  //   // Heading block
  //   return <Heading variant={style}>{props.children}</Heading>;
  // }
  // return BaseBlockContent.defaultSerializers.types.block(props)
}

export default BlockContent
