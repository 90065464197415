import React from 'react'
import Figure from './Figure'
import Video from './Video'
import Lottie from './Lottie'
import Testimonial from './Testimonial'
import Grid from './Grid'
import BlockContent from './BlockContent'
import Link from '../Link'

const serializers = {
  types: {
    block: props => <BlockContent {...props} />,
    videoFile: ({ node }) => <Video {...node} />,
    lottie: ({ node }) => <Lottie {...node} />,
    testimonial: ({ node }) => <Testimonial {...node} />,
    grid: data => <Grid {...data} />,
    mainImage: ({ node }) => <Figure node={node} />
  },
  marks: {
    link ({ mark, mark: { blank, href }, children }) {
      const props = {
        href
      }
      if (blank) {
        props.target = '_blank'
      }
      return <Link {...props}>{children}</Link>
    }
  }
}

export default serializers
