import React, { useState, useRef, useCallback } from 'react'
import Img from 'gatsby-image'
import { buildImageObj, getFluidGatsbyImage } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import { Waypoint } from 'react-waypoint'
import { Box } from 'rebass/styled-components'
import Lottie from 'react-lottie'

function LottieBlock ({ thumbnail, source }) {
  const [isStopped, setIsStopped] = useState(true)

  const onEnter = useCallback(async () => {
    setIsStopped(false)
  }, [isStopped, setIsStopped])

  const onLeave = useCallback(async () => {
    setIsStopped(true)
  }, [isStopped, setIsStopped])

  return (
    <Waypoint onEnter={onEnter} onLeave={onLeave}>
      <Box
        overflow='hidden'
        className='portableText--video'>
        <Box mx={['-15%', 0]}>

          <Lottie
            isStopped={isStopped}
            options={{
              loop: true,
              path: source.asset.url
            }}
          />
        </Box>
      </Box>
    </Waypoint>
  )
}

export default LottieBlock
