import React from "react";
import clientConfig from "../../../client-config";
import BasePortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";

const PortableText = (props) => (
  <BasePortableText serializers={serializers} {...clientConfig.sanity} {...props}/>
);

export default PortableText;
