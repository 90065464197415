import React from 'react'
import { Box } from 'rebass/styled-components'
import serializers from './serializers'
import PortableText from './index'

const Grid = ({ node, ...rest }) => {
  const { rows, columns, gap, gridItems } = node
  const styles = {}
  if (typeof gap !== 'undefined') {
    styles.gridGap = gap
  }
  if (typeof columns !== 'undefined') {
    styles.gridTemplateColumns = columns.map(c => `repeat(${c}, minmax(0px, 1fr))`)
  }
  if (typeof rows !== 'undefined') {
    styles.gridTemplateRows = rows.map(c => `repeat(${c}, minmax(0px, 1fr))`)
  }
  return (
    <Box
      className='portableText--grid'
      variant='grid'
      sx={styles}
    >
      {gridItems.map(item => (
        <Box key={item._key} sx={{
          gridRow: item.gridRow,
          gridColumn: item.gridCol
        }}>
          <PortableText blocks={item.content} />
        </Box>
      ))}
    </Box>
  )
}

export default Grid
