import React from 'react'
import { Box, Flex } from 'rebass/styled-components'
import { A, P, H1, H2, H3, H4, Underline } from '../Type'
import { Container } from '../UI'
import Animation from '../Animation'
import PortableText from './index'
import Figure from './Figure'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { getFluidGatsbyImage, getFixedGatsbyImage } from '../../lib/helpers'
import QuoteStart from '../icons/Quote Start.svg'
import QuoteEnd from '../icons/Quote End.svg'

const Wrapper = styled(Box)`
  p {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  p + p {
    margin-top: 1rem !important;
  }
`

const BioWrapper = styled.div`
  margin-left: 1rem;
  opacity: 0.4;

  p {
    letter-spacing: .02rem;
    font-weight: 800;
    font-size: .8rem;
    line-height: 1rem;
    text-transform: uppercase;
  }
  p + p {
    margin-top: 0 !important;
  }
`

const Testimonial = props => {
  const source = props.source[0]
  console.log('testimonial', props, source, source._type)
  return (
    <Container className='portableText--testimonial'>
      <Wrapper
        width={['100%', '60%']}
        sx={{
          maxWidth: '30rem',
          mx: 'auto'
        }}
        >
        <Box>
          <QuoteStart color={'orange'} height={2} width={2} ml={['-.5rem', '-1rem']} />
          <Box pl={2} pr={0} sx={{borderLeft: 1, borderLeftStyle: 'solid', borderLeftColor: 'orange'}}>
            <PortableText blocks={props.quote} />
          </Box>
          <Flex mt={1} justifyContent='flex-end'>
            {source._type === 'testimonialSourcePress' && (
            <Box maxWidth={150} flex='0 0 70%' opacity={0.5}>
              <Img fluid={getFluidGatsbyImage(source.image.asset, { maxWidth: 150 })} />
            </Box>
              )}
            {source._type === 'testimonialSourcePerson' && (
            <Flex alignItems='center' flex='1 0 80%'>
              <Img
                fixed={getFixedGatsbyImage(source.avatar.asset, { width: 100 })}
                style={{
                  borderRadius: '100%',
                  height: 100,
                  width: 100,
                  flex: '0 0 100px'
                }}
                  />
              <BioWrapper>
                <PortableText blocks={source.bio} />
              </BioWrapper>
            </Flex>
              )}
          </Flex>
        </Box>
      </Wrapper>
    </Container>
  )
}

export default Testimonial
