import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import PortableText from './portableText'
import { Box, Flex, Text, Heading } from 'rebass/styled-components'
import { Container } from './UI'
import styled, { ThemeProvider } from 'styled-components'
import SanityImage from './SanityImage'
import DownArrow from './DownArrow'
import FeaturedProjectsSection from './FeaturedProjectsSection'
import NextProjectModule from './NextProjectModule'
import { dark as darkTheme } from '../theme'

const PostBody = styled.div`

  p,
  .portableText--testimonial,
  .portableText--grid,
  .portableText--image,
  .portableText--video {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .portableText--grid .portableText--image {
    margin-bottom: 0rem;
    margin-top: 0rem;
  }

  p,
  h1,
  h2,
  h3,
  ol,
  h4 {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    font-weight: 200;
  }

  h1,
  h2,
  h3,
  h4 {
    //margin-top: 8rem;
    font-weight: 200;
    line-height: 1.1;
  }

  h1 + p,
  h2 + p {
    margin-top: 2rem;
  }

  h3 + p,
  h4 + p {
    margin-top: 1rem;
  }

  ol {
    padding-left: 2rem;
  }

  ol li {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

`

function BlogPost (props) {
  const { _rawBody, heroHeading, deliverables, _rawHeroImg, id } = props
  return (
    <Box>
      <Box
        bg='black'
        sx={{
          minHeight: ['90vh', '60vh'],
          display: 'grid',
          px: [2, 4, 9],
          pt: [8],
          pb: [2],
          gridRowGap: [1, 1, 1, 2],
          gridColumnGap: [1, 1, 2],
          gridTemplateRows: [
            'auto 0fr 0fr',
            'auto 0fr 0fr',
            'auto 0fr 0fr',
            'auto 0fr'
          ],
          gridTemplateColumns: [
            `repeat(10, minmax(0px, 1fr))`,
            `repeat(12, minmax(0px, 1fr))`
          ]
        }}
      >
        <Flex
          sx={{
            gridColumnStart: 1,
            gridColumnEnd: [10, 11, 11, 10],
            gridRowStart: 1,
            gridRowEnd: 2,
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Heading variant='h3' color='beige'>
            {heroHeading}
          </Heading>
        </Flex>

        <Flex
          sx={{
            gridColumnStart: [2, 7, 7, 2],
            gridColumnEnd: [11, 13, 13, 6],
            gridRowStart: 2,
            gridRowEnd: 3,
            flexDirection: 'column',
            justifyContent: 'flex-end',
            alignItems: 'flex-end'
          }}
        >
          {deliverables.map((d, i) => (
            <Text
              key={i}
              variant='accent'
              sx={{
                textAlign: 'right'
              }}
            >
              {d}
            </Text>
          ))}
        </Flex>
        <Flex
          sx={{
            position: 'relative',
            gridRowStart: 2,
            gridRowEnd: [4, 4, 4, 3],
            gridColumnStart: 1,
            gridColumnEnd: 2,
            alignItems: 'flex-end'
          }}
        >
          <DownArrow />
        </Flex>
        <Box
          sx={{
            gridRowStart: [3, 3, 3, 2],
            gridRowEnd: [4, 4, 4, 3],
            gridColumnStart: [2, 2, 2, 6],
            gridColumnEnd: [11, 13],
            mb: -6
          }}
        >
          <SanityImage node={_rawHeroImg} />
        </Box>
      </Box>

      <Box bg='white' py={4}>
        <PostBody>
          <Container>
            <PortableText blocks={_rawBody} />
          </Container>
        </PostBody>
      </Box>
      <ThemeProvider theme={darkTheme}>
        <NextProjectModule currentProjectId={id} />
      </ThemeProvider>
    </Box>
  )
}

export default BlogPost
