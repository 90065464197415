import React from 'react'
import SanityImage from '../SanityImage'

export default props => (
  <SanityImage
    style={{ height: '100%' }}
    className='portableText--image'
    {...props}
  />
)
