import React, { useState, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { Flex } from 'rebass/styled-components'

const fadeDown = y => keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0px, ${y}, 0px); 
  }
  100% {
    opacity: 1;
    transform: none;
  }
`

const StyledAnimatedArrow = styled.svg`
  animation-name: ${props => fadeDown(props.transformFromY)};
  animation-duration: 1500ms;
  animation-iteration-count: 1;
`

const AnimatedArrow = props => (
  <StyledAnimatedArrow
    width='22'
    height='31'
    viewBox='0 0 22 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M11 0L11 30' stroke='#FFF4E9' />
    <path d='M21 19.6318L11 29.6318L0.999999 19.6318' stroke='#FFF4E9' />
  </StyledAnimatedArrow>
)

const DownArrow = props => {
  const [transformFromY, setTransformFromY] = useState(null)
  const [bottom, setBottom] = useState(null)
  const ref = useRef()

  useEffect(() => {
    setTimeout(() => {
      const rect = ref.current.getBoundingClientRect()
      setTransformFromY(`-${rect.height}px`)
    }, 1000)
  }, [])

  return (
    <Flex
      minHeight={['100%', '50%']}
      width='100%'
      flexDirection='column'
      justifyContent='flex-end'
      ref={ref}
    >
      {transformFromY !== null && (
        <AnimatedArrow transformFromY={transformFromY} />
      )}
    </Flex>
  )
}

export default DownArrow
