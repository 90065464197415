import React, { useMemo } from 'react'
import { Box, Flex, Text, Heading } from 'rebass/styled-components'
import { Fade } from 'react-reveal'
import Img from './SanityImage'
import ArrowRight from '../components/icons/Arrow Right.svg'
import { Container } from './UI'
import { Link } from 'gatsby'
import useProjects from '../hooks/useProjects'

const NextProjectModule = ({ currentProjectId }) => {
  const projects = useProjects()

  const project = useMemo(() => {
    // Return next project in list, or default to the first
    for (let i = 0; i < projects.length - 1; i++) {
      if (projects[i].id === currentProjectId) {
        return projects[i + 1]
      }
    }
    return projects[0]
  }, [projects, currentProjectId])

  return (
    <Box bg='bgColor' py={5}>
      <Container>
        <Box
          sx={{
            maxWidth: '30rem',
            mx: 'auto'
          }}
        >
          <Text variant='accent'>Next Project</Text>
          <Fade bottom>
            <Link
              to={`/projects/${project.slug.current}`}
              style={{ textDecoration: 'none' }}
            >
              <Box>
                <Box my={1}>
                  <Img
                    node={project._rawPreviewImg || project._rawHeroImg}
                    disableMobileResizing={false}
                  />
                </Box>
                <Flex justifyContent='space-between' alignItems='center'>
                  <Heading as='h5' variant='h5' mb={0} color='textColor'>
                    {project.description || project.heroHeading}
                  </Heading>
                  <Box flexShrink={0} ml={1} width={2} height={2}>
                    <ArrowRight color='textColor' width='100%' height='100%' />
                  </Box>
                </Flex>
              </Box>
            </Link>
          </Fade>
        </Box>
      </Container>
    </Box>
  )
}

export default NextProjectModule
